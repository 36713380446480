@media screen and (max-width:500px) {
    .img-content-home{
      max-height: 12rem !important;
    }
    .space-row{
      margin: 30px 0px !important;
      padding: 40px  0px !important;
    
      
    }
  }


.card-body-actividades{
    background-color: rgb(121, 224, 114,0.2);
}
.cardTamaño{
    border: solid 2px;
}


.img-content-home{
    height: 15rem;
  }

  .texto-content {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin:40px 0px ;
  }



  .titulos-2 {
    
    font-size: 30px;
    color: black;
  }


  .space-row{
    margin: 40px 0px;
    background-color: white;
    padding: 50px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75); 
  
    
  }