@media screen and (max-width: 1200px) {
  .contImgOvino {
    display: none !important;
  }
  .FotoXs {
    display: flex !important;

    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .imgXs {
    margin: 0px 20px;
  }
  .divListasCronograma {
    margin: 100px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .desapareceXs {
    display: none;
  }
  .liItinerario {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 458px) {
  .liItinerario {
    font-size: 15px !important;
  }
  .divListasCronograma {
    margin: 50px 0px !important;
  }
}

.FotoXs {
  display: none;
}
.titulo {
  color: rgb(27, 118, 0);
}
.subtitulo {
  color: rgb(27, 118, 0);
}
.imgFormato {
  margin: 40px 0px;
  max-height: 300px;
  max-width: 300px;
  border-radius: 5px;
}
.contImgOvino {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.liItinerario {
  font-size: 23px;
  font-weight: bold;
}
.divListasCronograma {
  margin: 100px 10px;
}
.liSinPunto {
  list-style: none;
}

/* actividad 17/8 */
.cont-img-lanzamiento {
  margin-top: 30px;
  width: 100%; /* El div ocupará el ancho del contenedor padre */
  max-width: 1200px; /* Establece un ancho máximo si es necesario */
}
.cont-img-lanzamiento img {
  width: 100%; /* La imagen ocupará el ancho completo del div */
  height: auto; /* Asegura que la imagen se ajuste proporcionalmente */
  display: block; /* Elimina el espacio debajo de la imagen */
}
.img-actividades-salto-ovino {
  margin-top: 100px;
}

.contImage {
  width: 90%;
  height: auto;
  background-color: blue;
  border-radius: 5px;
}

.textALink {
  margin: 50px 30px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: white;
  color: #198754; /* Cambia el color a rojo (#ff0000) o el color que prefieras */
  text-decoration: none; /* Elimina el subrayado predeterminado de los enlaces */
  font-size: 15px;
  height: 60px;
  transition: background-color 0.3s;
}
.textALink:hover {
  background-color: #198754;
  color: white;
}
/* .textALink:hover .logo-descarga{
    background-color:#198754 ;
    color: white;
    background-image: url('../archivosGraficos/descargaTransicion.png'); 
} */

.logo-descarga {
  padding-right: 10px;
  width: 39px;
  height: 35px;
}
.cont-img-actividades {
  display: block;
}
.div-capsula-imagen {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
#divGuia{
  width: 300px;
}

#contentGuia{
  display: flex;
  align-items: center;
  justify-content: center;
}