@media screen and (max-width:1200px) {
    .cardTamaño{
        min-height: 600px !important;
    }
}

@media screen and (max-width:993px){
    .stlLista{
        
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin-bottom:1rem ;
        margin-top: 1rem;
    }
    .contPost{
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }
    .card{
        
        min-width: 90%;
        
    }
    .imgActivitis{
        height:400px !important;
    }
}
@media screen and (max-width:768px){
    .imgActivitis{
        
        height: 250px !important;
    }
}
@media screen and (max-width:528px) {
    .imgActivitis{
        height: 250px !important;
    }
}


.divPosteos{
    padding: 10px;
    
    /* background-color:  rgb(121, 224, 114,0.3); ; */
}
.imgActivitis{
    height: 250px;

}
.contPost{
    display: flex;
    padding: 5px ;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    
    
    /* height: ; */
}

.cardTamaño{
    min-height: 550px;
}

#cardGrande{
    width: 100% !important;
}