/* #loader{
   

} */

.text-spiner{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #00742D;
}
.spinner {
    box-shadow: 0 0 0 7px #00742D, inset 0 0 0 1px #00742D;
    position: relative;
    height: 50px;
    width: 230px;
    border-radius: 8px;
    overflow: hidden;
    animation: rotate_5132 6s linear infinite;
    z-index: 1;
  }
  
  .spinner:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #00742D;
    animation: load_5123 6s linear infinite;
  }
  
  @keyframes rotate_5132 {
    0%,
    42% {
      transform: rotate(0deg);
    }
  
    48%,
    92% {
      transform: rotate(180deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes load_5123 {
    0% {
      width: 0;
    }
  
    40%,
    50% {
      width: 100%;
    }
  
    90%,
    100% {
      width: 0;
    }
  }
  