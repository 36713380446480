@media screen and (max-width:993px ) {
    .card-slider{
        width: 300px;
        height: 800px !important;
   
    }
}
@media screen and (max-width:768px ) {
    .card-slider{
        width: 300px;
        height: 650px !important;
   
    }
}
@media screen and (max-width:490px ) {
    .card-slider{
        width: 250px !important;
        height: 500px !important;
   
    }
}

.card-slider{
    width: 300px;
    height: 500px;
    border-radius: 5px;
}
.swiper-slide{
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    height: auto;
}

.swiper-button-next{
    color: green;
}
.swiper-button-prev{
    color: green;
}

.swiper-hero .swiper-pagination{
    --swiper-pagination-color: #048f27;
    --swiper-pagination-bullet-size: 8px;
}