



@media screen and (max-width:993px) {
    .stlImagenes{
        height: 500px !important
    }

    .textHome{
        font-size: 30px !important;
        text-align: center;

    }
    .tituloHome{
        font-size: 50px !important;
    }
    .img-quienes-somos{


        margin: 20px 0px;
    }
}
@media screen and (max-width:768px){
    .stlImagenes{
        height: 300px !important;
    }
    .textHome{
        font-size: 25px !important;
    text-align: center;

    }
    .tituloHome{
        font-size: 40px !important;

    }
    .text-imagen-2{
        font-size: 20px !important;
        
      }
}
@media screen and (max-width:300px) {
    .textHome{
        font-size: 15px !important;
    text-align: center;

    }
    .tituloHome{
        font-size: 20px !important;
    }
}

#container-home{
    padding: 0px;
}

#Novedades{
    background-color: rgb(6, 36, 8,0.8);
    min-height: 15rem;
    font-size: 20px;
}

.stlImagenes{
    height: 800px;
}
.tituloHome{
    font-size: 60px;
    /* display: flex; */
    text-align: center;
    color: #048f27;
}
.textHome{
    color: rgb(0, 0, 0);
    font-size:40px;
}
.idImgHome{
    filter: brightness(70%);
    /* opacity:1.5 !important ; */
}

.class-text-carrusel{
    background-color: rgba(65, 66, 65, 0.5);
    border-radius: 5px;
}

.conten-image-1{
    background-image: url(../archivosGraficos/Directiva/fotoCampo.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 40rem;
    filter: brightness(100%);
    position: relative;

}
.conten-image-1::before{
    position: absolute;
    content: "";
    inset: 0;
    background-color: rgba(0, 0, 0, .4);

}

.titulo-h1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28rem;
    border-radius: 5px;
    position: relative;
}
 .titulo-h1::before{
    position: absolute;
    content: "";
    inset: 0;

 }

 .titulo-agrope{
    text-align: center;
    font-size: 50px;
    height: auto;
    color:white;
    border-radius: 5px;
    position: relative;
 
   
 }


 


.fluid-img-2{
    padding: 0px;
}

.container-quienes-somos{
    display: flex;
    align-items: center;
    height: 20rem;

}

.img-quienes-somos{

    width: 100%;
    height: auto;
    border-radius: 5px;
}
.titulo-page{
    width: 100%;
    height: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.titulo-home{
   text-align: center;
    font-size: 50px;
    height: auto;
    color:white;

    background-color: rgb(0,0,0,0.2);
    border-radius: 5px;

}

.carrusel-inner-home{
    background-color: rgb(0,0,0,0.2);

}
.card-home{
    width: 300px;
    height: 500px;
    margin: 0 10px;
}


.conten-image-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../archivosGraficos/Home/imgJuraDeOvejas.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 30rem;
    
    position: relative;
  }
.conten-image-2::before {
    position: absolute;
    content: "";
   inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }


  
  .text-imagen-2{
    font-size: 25px;
    color: white;
    text-align: center;
    font-weight: 900;
    position: relative;
  }