@media screen and (max-width: 993px) {
  .textoFooterNombre {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 764px) {
  .textoFooterNombre {
    font-size: 20px !important;
  }
  .linea1 {
    border-left: none !important;
  }
}
@media screen and (max-width: 504px) {
  .textoFooterNombre {
    font-size: 19px !important;
  }
  .linea1 {
    border-left: none;
  }

  #mapa{
    width: 300px !important;
  }
}

#divContactanos {
  color: white;
  font-size: 22px;
  padding-bottom: 30px;
}
footer {
  background-color: rgb(167, 14, 14);
  padding-left: 1rem;
  min-height: 100px;
  width: 100%;
  /* text-align: center; */
}

/* @media screen and (max-width:768px) {
    footer{
        display: flex;
        height: 300px;
    }
}
posible background  rgb(167, 14, 14);
*/

.logoText {
  display: flex;

  text-decoration: none;
  width: max-content;
  background-color: rgba(15, 61, 6, 0.2);
  margin: 5px;
  padding: 3px;
  border-radius: 5px;
  height: 40px;
}
.logoText-not-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: max-content;

  margin: 5px;
  padding: 3px;
  border-radius: 5px;
}

.infoEnlaces {
  display: flex;
  text-decoration: none;
  margin: 0px;
  align-items: center;
  justify-content: center;
}
.textoFooter {
  color: rgb(247, 228, 228);
  padding-left: 5px;
}
.textoFooter-links {
  margin: 0px;

  color: rgb(247, 228, 228);
  padding-left: 5px;
}
.textoFooterNombre {
  margin-top: 10px;
  font-size: 25px;
  color: rgb(247, 228, 228);
}
.divCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.linea1 {
  border-left: solid 2px rgb(46, 44, 44);
}


#mapa{
  width: 500px;
  height: 375px;
  border: 0px;
}